import React, { Component } from 'react';
import { array, bool, func, number, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { calculateQuantityFromHours, timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import {
  Form,
  IconSpinner,
  PrimaryButton,
  SectionOpeningHoursMaybe,
  FieldCheckbox,
  NamedLink,
  InfoBox,
} from '../../components';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import * as validators from '../../util/validators';

import css from './BookingTimeForm.module.css';

export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const { bookingStartTime, bookingEndTime } = formValues.values;
    const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
    const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;

    const quantity = calculateQuantityFromHours(startDate, endDate, this.props.timeZone);
    let priceForBooking;

    if (this.props.priceDiscount2 && quantity >= 7) {
      priceForBooking = this.props.priceDiscount2;
    } else if (this.props.priceDiscount1 && quantity >= 4) {
      priceForBooking = this.props.priceDiscount1;
    } else {
      priceForBooking = this.props.price;
    }

    // This is the place to collect breakdown estimation data. See the
    // EstimatedBreakdownMaybe component to change the calculations
    // for customized payment processes.
    const bookingData =
      startDate && endDate
        ? {
            unitType: this.props.unitType,
            unitPrice: priceForBooking,
            startDate,
            endDate,
            quantity,
            timeZone: this.props.timeZone,
          }
        : null;

    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    const isSameTime = bookingStartTime === bookingEndTime;

    if (bookingStartTime && bookingEndTime && !isSameTime && !this.props.fetchLineItemsInProgress) {
      this.props.onFetchTransactionLineItems({
        bookingData,
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      price,
      priceDiscount1,
      priceDiscount2,
      openingHours,
      isAuthenticated,
      licenceExpired,
      onOpenUpdateLicenceModal,
      contactDetailsChanged,
      leadTime,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    if (!price) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (price.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={price}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            endTimePlaceholder,
            startTimePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            isProvider,
            listingId,
            submitButtonWrapperClassName,
            unitPrice,
            unitType,
            values,
            monthlyTimeSlots,
            onFetchTimeSlots,
            timeZone,
            publicData,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            paidForQuantity,
            formId,
            inProgress,
            invalid,
            isStartDayOutsideRange,
            requiresElectricBrakes,
          } = fieldRenderProps;

          const electricBrakeContent = (
            <>
              To tow this trailer your vehicle will need an electric brake controller. Please read
              our{' '}
              <NamedLink name="ExplainElectricBrakesPage">
                Electric brake controllers page
              </NamedLink>{' '}
              to verify you can hire this trailer.
            </>
          );

          const startTime = values && values.bookingStartTime ? values.bookingStartTime : null;
          const endTime = values && values.bookingEndTime ? values.bookingEndTime : null;

          const bookingStartLabel = isProvider ? (
            <FormattedMessage
              id="BookingTimeForm.providerBookingStartTitle"
              values={{ customerName: this.props.customerName }}
            />
          ) : (
            <FormattedMessage id="BookingTimeForm.bookingStartTitle" />
          );

          const bookingEndLabel = isProvider ? (
            <FormattedMessage
              id="BookingTimeForm.providerBookingEndTitle"
              values={{ customerName: this.props.customerName }}
            />
          ) : (
            <FormattedMessage id="BookingTimeForm.bookingEndTitle" />
          );

          const startDate = startTime ? timestampToDate(startTime) : null;
          const endDate = endTime ? timestampToDate(endTime) : null;
          const quantity = calculateQuantityFromHours(startDate, endDate, timeZone);

          let priceForBooking;

          if (priceDiscount2 && quantity >= 7) {
            priceForBooking = priceDiscount2;
          } else if (priceDiscount1 && quantity >= 4) {
            priceForBooking = priceDiscount1;
          } else {
            priceForBooking = unitPrice;
          }

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const bookingData =
            startDate && endDate
              ? {
                  unitType,
                  unitPrice: priceForBooking,
                  startDate,
                  endDate,
                  quantity,
                  timeZone,
                }
              : null;

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const bookingInfoMaybe = showEstimatedBreakdown ? (
            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
              </h3>
              <EstimatedBreakdownMaybe bookingData={bookingData} lineItems={lineItems} />
            </div>
          ) : null;

          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingTimeForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };

          const showCustomerInitiatedCheckbox = formId === 'ChangeBookingModal' && isProvider;

          const customerInitiatedLabel = (
            <span className={css.customerInitiatedText}>
              <FormattedMessage
                id="ChangeBookingModal.providerConfirmation"
                values={{ customerName: this.props.customerName }}
              />
            </span>
          );

          // TODO this message is not being rendered, just cannot click submit
          const customerInitiatedRequiredMessage = intl.formatMessage({
            id: 'ChangeBookingModal.customerInitiatedRequired',
          });

          const customerInitiatedRequired = validators.requiredCheckbox(
            customerInitiatedRequiredMessage
          );

          const submitButtonId = formId => {
            if (formId === 'ChangeBookingModal') {
              return 'BookingTimeForm.requestToChangeBooking';
            } else if (!isAuthenticated) {
              return 'BookingTimeForm.signIn';
            } else {
              return 'BookingTimeForm.requestToBook';
            }
          };

          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress || licenceExpired;

          const leadTime = publicData?.leadTime || 0;
          const minDuration = publicData?.minDuration || 0;

          const minDurationOption = config.custom.minDurationOptions.find(
            option => option.hours === parseInt(minDuration)
          );

          return (
            <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  this.handleOnChange(values);
                }}
              />
              {requiresElectricBrakes ? (
                <div className={css.infoBoxWrapper}>
                  <InfoBox content={electricBrakeContent} infoType={'information'} />
                </div>
              ) : null}
              {!!minDuration && !paidForQuantity ? (
                <div className={css.infoBoxWrapper}>
                  <InfoBox
                    content={
                      <FormattedMessage
                        id="ListingPage.minDuration"
                        values={{ minDuration: minDurationOption.label }}
                      />
                    }
                  />
                </div>
              ) : null}
              {monthlyTimeSlots && timeZone ? (
                <FieldDateAndTimeInput
                  {...dateInputProps}
                  className={css.bookingDates}
                  listingId={listingId}
                  bookingStartLabel={bookingStartLabel}
                  onFetchTimeSlots={onFetchTimeSlots}
                  monthlyTimeSlots={monthlyTimeSlots}
                  values={values}
                  intl={intl}
                  form={form}
                  pristine={pristine}
                  timeZone={timeZone}
                  openingHours={openingHours}
                  leadTime={leadTime}
                  minDuration={minDuration}
                  paidForQuantity={paidForQuantity}
                  startTimePlaceholder={startTimePlaceholder}
                  endTimePlaceholder={endTimePlaceholder}
                  isStartDayOutsideRange={isStartDayOutsideRange}
                />
              ) : null}

              {bookingInfoMaybe}
              {loadingSpinnerMaybe}
              {bookingInfoErrorMaybe}
              {isOwnListing ? (
                <p className={css.smallPrint}>
                  <FormattedMessage id="BookingTimeForm.ownListing" />
                </p>
              ) : (
                ''
              )}
              {showCustomerInitiatedCheckbox ? (
                <div className={css.customerInitiatedCheckbox}>
                  <FieldCheckbox
                    id={formId ? `${formId}.customerInitiated` : 'customerInitiated'}
                    name="customerInitiated"
                    label={customerInitiatedLabel}
                    validate={customerInitiatedRequired}
                    value="customerInitiated"
                  />
                </div>
              ) : null}
              {licenceExpired ? (
                <div className={css.infoBoxWrapper}>
                  <InfoBox
                    content={<FormattedMessage id="LicenceDetailsForm.licenceExpiredWarning" />}
                    infoType={'information'}
                  />
                  <a className={css.primaryButton} onClick={onOpenUpdateLicenceModal}>
                    <FormattedMessage id="LicenceDetailsForm.licenceUpdateButton" />
                  </a>
                </div>
              ) : null}
              {contactDetailsChanged ? (
                <div className={css.infoBoxWrapper}>
                  <InfoBox
                    content={<FormattedMessage id="LicenceDetailsForm.licenceUpdated" />}
                    infoType={'success'}
                  />
                </div>
              ) : null}
              <div className={submitButtonClasses}>
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id={submitButtonId(formId)} />
                </PrimaryButton>
              </div>
              <div className={css.sectionOpeningHoursMaybe}>
                <SectionOpeningHoursMaybe publicData={publicData} />
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  priceDiscount1: null,
  priceDiscount2: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  publicData: null,
  lineItems: null,
  fetchLineItemsError: null,
  formId: null,
  isStartDayOutsideRange: () => false,
  requiresElectricBrakes: false,
  contactDetailsChanged: false,
  leadTime: 0,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  formId: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  priceDiscount1: propTypes.money,
  priceDiscount2: propTypes.money,
  isOwnListing: bool,
  isProvider: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  publicData: object,
  requiresElectricBrakes: bool,
  contactDetailsChanged: bool,
  leadTime: number,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
  startTimePlaceholder: string,
  endTimePlaceholder: string,

  // for changing booking
  paidForQuantity: number,
  isStartDayOutsideRange: func,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
