import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '..';
import { Button, FieldNumberInput, SecondaryButton } from '../../components';
import { validPositiveNumber, composeValidators } from '../../util/validators';

import css from './EditListingSubscriptionPanel.module.css';
import { Form } from 'react-final-form';

const EditListingSubscriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    onSubmit,
    errors,
    intl,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const { privateData } = currentListing.attributes;
  const savedSubscription = publicData.subscription || 'premium';
  const [selectedListingType, setSelectedListingType] = useState(savedSubscription);
  const [nextBillingDate, setNextBillingDate] = useState('2025-01-31');
  const [isTrailerValueSet, setIsTrailerValueSet] = useState(!!privateData.assetValue);

  const assetValueMessage = intl.formatMessage({
    id: 'EditListingSubscriptionPanel.assetValue',
  });
  const assetValueHint = intl.formatMessage({
    id: 'EditListingSubscriptionPanel.assetValueHint',
  });
  const assetValuePlaceholderMessage = intl.formatMessage({
    id: 'EditListingSubscriptionPanel.assetValuePlaceholder',
  });
  const assetValueInvalidMessage = intl.formatMessage({
    id: 'EditListingSubscriptionPanel.assetValueInvalid',
  });

  const trailerValue = privateData.assetValue || '';

  const premiumPrice =
    trailerValue && trailerValue >= 20 ? Math.max(Number(trailerValue) / 24, 20).toFixed(2) : '20';

  const handleListingTypeSelect = type => {
    setSelectedListingType(type);
  };

  const handleFormSubmit = () => {
    onSubmit({ subscription: selectedListingType });
  };

  const handleSubmitAssetValue = values => {
    onSubmit({ assetValue: values.assetValue });
    setIsTrailerValueSet(true);
  };

  return (
    <div className={classes}>
      <h1 className={css.title}>
        <FormattedMessage
          id="EditListingSubscriptionPanel.title"
          values={{
            listingTitle: (
              <ListingLink listing={listing}>{currentListing.attributes.title}</ListingLink>
            ),
          }}
        />
      </h1>
      <div className={css.trailerValueWrapper}>
        <div className={css.textWrapper}>
          <p>
            Regardless of the trailer type you own, a Premium Listing is perfect for any trailer.
            Pricing is based on the trailer's type and market value.
          </p>
          <p>
            Enter the value of your {publicData.category} trailer to view the Premium Listing price:
          </p>
        </div>

        <Form
          onSubmit={handleSubmitAssetValue}
          initialValues={{
            assetValue: privateData.assetValue || '',
          }}
        >
          {({ handleSubmit, form, values }) => {
            const updateDisabled = values.assetValue === privateData.assetValue;

            return (
              <form onSubmit={handleSubmit}>
                <div className={css.formWrapper}>
                  <label>Trailer value</label>
                  <div className={css.inputWrapper}>
                    <span className={css.dollarSign}>$</span>
                    <FieldNumberInput
                      name="assetValue"
                      component="input"
                      type="number"
                      className={css.input}
                      label={assetValueMessage}
                      hint={assetValueHint}
                      placeholder={assetValuePlaceholderMessage}
                      validate={composeValidators(validPositiveNumber(assetValueInvalidMessage))}
                    />
                  </div>
                  <SecondaryButton
                    rootClassName={css.submitTrailerValueButton}
                    inProgress={updateInProgress}
                    disabled={updateDisabled}
                    ready={panelUpdated && updateDisabled}
                  >
                    {isTrailerValueSet ? 'Save' : 'Calculate Premium Listing Price'}
                  </SecondaryButton>
                  <p className={css.infoText}>
                    *Prices for Premium listings start at $20 per month. Input your trailer's value
                    to see the precise cost and ensure it’s fully covered.
                  </p>
                </div>
              </form>
            );
          }}
        </Form>
      </div>

      <div className={css.boxWrapper}>
        <div
          className={classNames(css.listingTypeBox, {
            [css.selected]: selectedListingType === 'standard',
          })}
          onClick={() => handleListingTypeSelect('standard')}
        >
          <h2>Standard</h2>
          <h2>$0 per month</h2>
          <div>
            Includes:
            <ul>
              <li>Automated bookings and payment</li>
              <li>Free to list trailers</li>
              <li>Control your price and trailer availability</li>
              <li>Booking meows</li>
              <li>Owner dashboard with earnings</li>
              <li>Support</li>
              <li>The trailer guarantee</li>
            </ul>
          </div>
          <SecondaryButton className={css.listingTypeButton}>
            {selectedListingType === 'standard'
              ? 'Standard Listing Type Selected'
              : 'Select Standard Listing Type'}
          </SecondaryButton>
        </div>

        <div
          className={classNames(css.listingTypeBox, {
            [css.selected]: selectedListingType === 'premium',
          })}
          onClick={() => handleListingTypeSelect('premium')}
        >
          <h2>Premium</h2>
          <h2>
            {!isTrailerValueSet && 'from '}${premiumPrice} per month
          </h2>
          <div>
            Includes all of the Standard listing type features plus:
            <ul>
              <li>Comprehensive cover</li>
              <li>Roadside assistance</li>
              <li>Premium support</li>
              <li>Extra promotion</li>
              <li>Exclusive discount codes</li>
              <li>Configure instant book</li>
              <li>Custom QR code stickers printed for you</li>
              <li>Sticker refresh whenever you need</li>
              <li>Marketing guide</li>
            </ul>
          </div>
          <SecondaryButton className={css.listingTypeButton}>
            {selectedListingType === 'premium'
              ? 'Premium Listing Type Selected'
              : 'Select Premium Listing Type'}
          </SecondaryButton>
          {selectedListingType === 'standard' && savedSubscription === 'premium' && (
            <p className={css.infoMessage}>
              <FormattedMessage
                id="EditListingSubscriptionPanel.subscriptionChangeMessage"
                values={{ endDate: new Date(nextBillingDate).toLocaleDateString() }}
              />
            </p>
          )}
        </div>
        {errors && errors.updateListingError && (
          <p className={css.error}>
            <FormattedMessage id="EditListingSubscriptionPanel.updateFailed" />
          </p>
        )}
        <Button
          className={css.submitButton}
          type="submit"
          inProgress={updateInProgress}
          onClick={handleFormSubmit}
          ready={panelUpdated && selectedListingType === savedSubscription}
        >
          {submitButtonText}
        </Button>
      </div>
    </div>
  );
};

EditListingSubscriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingSubscriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onSubmit: func.isRequired,
  errors: object.isRequired,
};

export default EditListingSubscriptionPanel;
